import React from "react";
import { gql, useQuery } from "@apollo/client";
import styled from "styled-components";
import ActionBar from "../components/OldActionBar";
import Button from "../components/buttons/Button";
import { navigate } from "gatsby-link";
import Link from "../components/Link";
import PageWrapper from "../components/PageWrapper";
import queryString from "query-string";
import { APPROVED } from "../constants";
import DarkPageHeading from "../components/DarkPageHeading";

const GET_ALL_TIEBREAKER_TEMPLATES = gql`
  query GetAllTiebreakerTemplates {
    tiebreakerTemplates: getAllTiebreakerTemplates {
      id
      text
      status
    }
  }
`;

const TiebreakerTemplateListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TiebreakerTemplateListingWrapper = styled.div`
  font-size: 20px;
  margin-bottom: 15px;
`;

export default function QuizList() {
  const { eventId, quizId } = queryString.parse(location.search);

  const { data } = useQuery(GET_ALL_TIEBREAKER_TEMPLATES);

  if (!data) {
    return null;
  }

  const tiebreakerTemplates = data.tiebreakerTemplates;

  const handleCancel = () => {
    if (eventId) {
      navigate(`/create-edit-event?eventId=${eventId}`);
    } else {
      navigate(`/create-edit-quiz?quizId=${quizId}`);
    }
  };

  const approvedTiebreakerTemplates = tiebreakerTemplates.filter(
    (template) => template.status === APPROVED
  );

  return (
    <PageWrapper>
      <DarkPageHeading>Select an Approved Tiebreaker</DarkPageHeading>
      <TiebreakerTemplateListContainer>
        {approvedTiebreakerTemplates.map((template) => (
          <TiebreakerTemplateListingWrapper key={template.id}>
            <Link
              to={`/preview-tiebreaker-template?eventId=${eventId}&quizId=${quizId}&tiebreakerTemplateId=${template.id}`}
            >
              {template.text}
            </Link>
          </TiebreakerTemplateListingWrapper>
        ))}
      </TiebreakerTemplateListContainer>
      <ActionBar>
        <Button size="large" onClick={handleCancel}>
          Cancel
        </Button>
      </ActionBar>
    </PageWrapper>
  );
}
